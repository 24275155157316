export default {
  data: () => ({
    includes: [],
    key: 0,
    loading: false,
    model: {},
    url: null,
  }),

  methods: {
    async fetch() {
      this.loading = true;

      let params = {};

      if (this.includes.length > 0) {
        params.include = this.includes.toString();
      }

      try {
        let result = await this.$axios.get(
          `${this.url}/${this.$route.params.id}`,
          params
        );
        this.model = Object.assign({}, this.model, result.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
