var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-row',{staticClass:"ma-2",attrs:{"dense":""}},[_c('v-col',{staticClass:"px-2"},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"Search","prepend-inner-icon":"mdi-magnify"},on:{"input":_vm.query},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"dense":"","outlined":"","items":[
              { name: 'Show coupons with prize', value: 'ne null' },
              { name: 'Show coupons without prize', value: 'eq null' },
              { name: 'Show all', value: null } ],"item-value":"value","item-text":"name","label":"Filter"},on:{"input":_vm.query},model:{value:(_vm.prizeFilter),callback:function ($$v) {_vm.prizeFilter=$$v},expression:"prizeFilter"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"dense":"","outlined":"","items":[
              { name: 'Invalid', value: false },
              { name: 'Valid', value: true } ],"item-value":"value","item-text":"name","label":"Validity"},on:{"input":_vm.query},model:{value:(_vm.selectedValidity),callback:function ($$v) {_vm.selectedValidity=$$v},expression:"selectedValidity"}})],1)],1),_c('v-divider'),_c('v-data-table',{key:_vm.couponCodeListKey,attrs:{"hide-default-header":"","footer-props":{ 'items-per-page-options': [10, 30, 60, 100] },"items":_vm.data,"loading":_vm.loading,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"update:options":_vm.paginate},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var items = ref.items;
return [_c('v-card',{staticClass:"d-flex flex-wrap justify-space-around pa-3",attrs:{"flat":"","tile":""}},_vm._l((items),function(item){return _c('v-card',{key:item.id,staticClass:"my-2",attrs:{"width":"350","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"overline"},[_vm._v(" "+_vm._s(item.isValid ? 'valid' : 'invalid')+" ")]),_c('v-list-item-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(item.code)+" ")]),_c('v-file-input',{attrs:{"dense":"","hide-details":"","outlined":"","single-line":"","accept":".jpg,.jpeg,.png","append-icon":"mdi-paperclip","label":"Upload prize","prepend-icon":""},on:{"change":function($event){return _vm.uploadImage(item)}},model:{value:(item.image),callback:function ($$v) {_vm.$set(item, "image", $$v)},expression:"item.image"}})],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"105","color":"grey"}},[(item.prize)?_c('v-img',{staticClass:"my-4",attrs:{"max-height":"105","max-width":"105","src":item.prize}}):_vm._e()],1)],1)],1)}),1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }