<template>
  <v-container fluid>
    <v-row>
      <v-col class="pa-0">
        <v-divider></v-divider>
        <v-data-table
          :items-per-page="10"
          :headers="headers"
          :items="documents"
        >
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  :loading="downloading"
                  @click.stop="download(item)"
                >
                  <v-icon color="blue-grey">mdi-download</v-icon>
                </v-btn>
              </template>
              <span> dowload </span>
            </v-tooltip>
          </template></v-data-table
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { downloadBase64 } from '../../../helpers/file';

export default {
  props: {
    couponCount: {
      required: false,
    },
  },

  data() {
    return {
      couponsPerDoc: parseInt(process.env.COUPONS_PER_DOC),
      documents: [],
      downloading: false,
      headers: [
        { text: '#', value: 'offset' },
        { text: 'From', value: 'from' },
        { text: 'To', value: 'to' },
        { text: 'Actions', align: 'right', value: 'action', sortable: false },
      ],
    };
  },

  created() {
    let count = 0;
    let offset = 1;
    while (count < this.couponCount) {
      this.documents.push({
        offset: offset,
        from: count + 1,
        to:
          this.couponCount - count < this.couponsPerDoc
            ? count + (this.couponCount - count)
            : count + this.couponsPerDoc,
      });
      count = count + this.couponsPerDoc;
      offset = offset + 1;
    }
  },

  methods: {
    async download(item) {
      try {
        this.downloading = true;
        let res = await this.$axios.get(
          `promotions/${this.$route.params.id}/coupons-pdf?limit=${this.couponsPerDoc}&offset=${item.offset}`
        );
        downloadBase64(
          res.data,
          'application/pdf',
          `coupons-${item.from}-to-${item.to}.pdf`
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.downloading = false;
      }
    },
  },
};
</script>
