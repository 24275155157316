<template>
  <v-layout>
    <v-btn block small tile @click="update(item)">Edit</v-btn>
    <v-dialog
      persistent
      v-model="dialog"
      width="640px"
      @keydown.esc="dialog = false"
    >
      <promotion-form
        :model="editItem"
        :key="key"
        @update="handleUpdate"
        @close="reset"
      ></promotion-form>
    </v-dialog>
  </v-layout>
</template>

<script>
import BaseList from '../../../helpers/base-list';
import PromotionForm from './PromotionForm';

export default {
  extends: BaseList,

  props: {
    item: { required: true },
  },

  components: {
    PromotionForm,
  },

  created() {
    this.url = 'promotions';
  },

  methods: {
    handleUpdate() {
      this.reset();
      this.$emit('updated');
    },
  },
};
</script>
