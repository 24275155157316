<template>
  <v-container fluid>
    <v-row>
      <v-col class="pa-0">
        <v-row dense class="ma-2">
          <v-col class="px-2">
            <v-text-field
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="Search name, identity no, mobile, coupon code"
              prepend-inner-icon="mdi-magnify"
              @input="query"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="px-2">
            <v-select
              dense
              outlined
              v-model="winnersFilter"
              :items="[
                { name: 'Show winners', value: 'ne null' },
                { name: 'Show all', value: null },
              ]"
              item-value="value"
              item-text="name"
              label="Filter"
              @input="query"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-data-table
          hide-default-header
          :footer-props="{ 'items-per-page-options': [10, 30, 60, 100] }"
          :items="data"
          :loading="loading"
          :options="pagination"
          :server-items-length="totalItems"
          @update:options="paginate"
        >
          <template v-slot:body="{ items }">
            <v-card
              flat
              tile
              class="d-flex flex-wrap justify-space-around pa-3"
            >
              <v-card
                v-for="item in items"
                :key="item.id"
                class="my-2"
                width="350"
                outlined
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="d-flex justify-space-between mb-0">
                      <span class="caption">
                        {{ item.createdAt | formatDate('YYYY/MM/DD HH:mm') }}
                      </span>
                      <v-chip x-small>{{ item.couponCode.code }}</v-chip>
                    </div>
                    <v-list-item-title class="title mb-0">
                      {{ item.name }}
                    </v-list-item-title>
                    <div class="d-flex justify-space-between mb-0">
                      <v-list-item class="pl-0">
                        <v-list-item-content class="pt-2 pb-0">
                          <v-list-item-subtitle class="caption mb-0">
                            Identity no
                          </v-list-item-subtitle>
                          <v-list-item-title class="body-2">
                            {{ item.idCardNo }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="pl-0">
                        <v-list-item-content class="pt-2 pb-0">
                          <v-list-item-subtitle class="caption mb-0">
                            Mobile
                          </v-list-item-subtitle>
                          <v-list-item-title class="body-2">
                            {{ item.mobile }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-list-item-content>
                  <v-list-item-avatar
                    v-if="item.couponCode.prize"
                    tile
                    size="80"
                    color="grey"
                  >
                    <v-img
                      v-if="item.couponCode.prize"
                      max-height="80"
                      max-width="80"
                      :src="item.couponCode.prize"
                      class="my-4"
                    ></v-img
                  ></v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-card>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseList from '../../../helpers/base-list';

export default {
  extends: BaseList,

  components: {},

  props: {
    promotionId: {
      required: false,
    },
  },

  data() {
    return {
      sortField: 'id,desc',
      winnersFilter: null,
    };
  },

  created() {
    this.url = 'responses';
  },

  methods: {
    searchQuery(term) {
      return `(name like ${term}||mobile like ${term}||idCardNo like ${term}||couponCode.code like ${term})`;
    },

    customParams(params) {
      params.filter =
        (typeof params.filter == 'string' ? `${params.filter}|` : '') +
        `promotionId eq ${this.promotionId}`;

      if (this.winnersFilter) {
        params.filter =
          (typeof params.filter == 'string' ? `${params.filter}|` : '') +
          `couponCode.prize ${this.winnersFilter}`;
      }
      return params;
    },
  },
};
</script>
