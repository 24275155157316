<template>
  <v-card outlined :loading="loading">
    <v-card-title class="px-5">Set Coupon Image</v-card-title>
    <v-card-text class="pa-5 pt-2">
      <v-row>
        <v-col cols="12" v-if="!couponImage">
          <v-file-input
            v-model="selectedImage"
            dense
            hide-details
            outlined
            show-size
            accept=".jpg,.jpeg,.png"
            label="Image to upload"
          ></v-file-input>
        </v-col>
        <v-col cols="12" v-show="couponImage">
          <div id="couponImageDiv" style="position: relative">
            <img id="qr" class="draggable" @mousedown="startDragQR" />
            <!-- <div class="draggable sample-url" @mousedown="startDragURL">
              <p>{{ sampleUrl }}</p>
            </div> -->
          </div>
        </v-col>
        <v-col cols="12">
          <v-btn
            v-if="!couponImage"
            depressed
            color="primary"
            :loading="loading"
            @click="uploadImage"
          >
            Upload
          </v-btn>
          <v-btn
            v-else
            depressed
            color="primary"
            :loading="loading"
            @click="savePosition"
          >
            Save
          </v-btn>
          <v-btn
            outlined
            text
            class="mx-3"
            :disabled="loading"
            @click="close()"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import QRCode from 'qrcode';
import dragger from '../../../helpers/dragger';

export default {
  props: {
    promotion: { required: true },
  },

  data() {
    return {
      couponImage: null,
      loading: false,
      maxWidth: 0,
      maxHeight: 0,
      qrSize: 105, //conveted at 150ppi
      sampleUrl: `${process.env.APP_URL}/coupon/SAMPLE`,
      selectedImage: null,

      QRPosition: { top: '0px', left: '0px' },
      URLPosition: { top: '0px', left: '0px' },
    };
  },

  computed: {},

  methods: {
    startDragQR(event) {
      dragger.startDrag(
        event,
        this.maxWidth - this.qrSize,
        this.maxHeight - this.qrSize,
        (position) => {
          this.QRPosition = position;
        }
      );
    },

    // startDragURL(event) {
    //   dragger.startDrag(
    //     event,
    //     this.maxWidth - this.qrSize,
    //     this.maxHeight - this.qrSize,
    //     (position) => {
    //       this.URLPosition = position;
    //     },
    //     -this.qrSize
    //   );
    // },

    async uploadImage() {
      if (!this.selectedImage) return;

      this.loading = true;
      let formData = new FormData();
      formData.append('image', this.selectedImage, this.selectedImage.name);

      try {
        await this.$axios.post(
          `promotions/${this.promotion.id}/coupon-image`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );

        await this.$axios
          .get(`promotions/${this.promotion.id}/coupon-image`)
          .then((response) => {
            this.couponImage = response.data;
            this.setImage();
          });
      } catch (error) {
        console.error(error);
        this.$root.$toast.open('There was an error!', 'error');
      } finally {
        this.loading = false;
      }
    },

    async setImage() {
      let div = document.getElementById('couponImageDiv');
      div.style.backgroundImage = `url('${this.couponImage}')`;

      let img = new Image();
      img.src = this.couponImage;
      img.onload = () => {
        this.maxWidth = img.width;
        this.maxHeight = img.height;
        div.style.width = `${img.width}px`;
        div.style.height = `${img.height}px`;
      };

      const qrCode = await QRCode.toDataURL(this.sampleUrl, {
        width: this.qrSize,
      });
      let qrImage = document.getElementById('qr');
      qrImage.src = qrCode;
    },

    async savePosition() {
      let couponProperties = this.promotion.couponProperties || {};
      couponProperties.QRPosition = this.QRPosition;
      couponProperties.URLPosition = this.URLPosition;

      try {
        await this.$axios.put(`promotions/${this.promotion.id}`, {
          couponProperties: couponProperties,
        });
        this.$root.$toast.open('Coupon Saved', 'success');
        this.$emit('close');
        this.$emit('updated');
      } catch (error) {
        console.error(error);
        this.$root.$toast.open('There was an error', 'error');
      }
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.draggable {
  position: relative;
  cursor: move;
}

.draggable > * {
  pointer-events: none;
}

.sample-url {
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 0px;
  display: table;
  color: rgb(59, 60, 63);
  background-color: rgb(253, 253, 253);
}

.sample-url p {
  margin-bottom: 0px;
}
</style>
