<template>
  <v-container fluid>
    <v-row>
      <v-progress-circular
        v-if="loading"
        indeterminate
        class="mx-auto my-8"
        color="primary"
        :size="50"
      ></v-progress-circular>
      <v-col v-else class="pa-0">
        <v-toolbar flat>
          <v-app-bar-nav-icon @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>{{ model.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            v-if="remainingDays > 0"
            left
            bottom
            transition="scroll-y-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0">
              <v-list-item @click="terminatePromotion()">
                <v-list-item-content>
                  <v-list-item-title>Terminate Promotion</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="generateView = true">
                <v-list-item-content>
                  <v-list-item-title>Generate Coupons</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="positionDialog = true">
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      `${
                        model.couponImage && model.couponProperties
                          ? 'Update'
                          : 'Set'
                      } Coupon Image`
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>

        <v-card-text class="py-3">
          <v-container fluid class="pa-0">
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="6" class="py-0">
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>mdi-calendar</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-subtitle> Duration </v-list-item-subtitle>
                        <v-list-item-title>
                          {{ model.startDate | formatDate }} to
                          {{ model.endDate | formatDate }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6" class="py-0">
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>mdi-web</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          Promotion URL
                        </v-list-item-subtitle>
                        <v-list-item-title>{{ appUrl }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-card flat outlined>
                      <v-list-item>
                        <v-list-item-icon class="my-5">
                          <v-icon>mdi-calendar-clock</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-left">
                            Remaining Days
                          </v-list-item-subtitle>
                          <v-list-item-title class="headline text-right">
                            {{ remainingDays | commaSeparate }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-card-actions class="pa-0">
                        <promotion-edit
                          :item="this.model"
                          @updated="fetch()"
                        ></promotion-edit>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card flat outlined>
                      <v-list-item>
                        <v-list-item-icon class="my-5">
                          <v-icon>mdi-qrcode-scan</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-left">
                            Coupons
                          </v-list-item-subtitle>
                          <v-list-item-title
                            v-if="model.summary"
                            class="headline text-right"
                          >
                            {{ model.summary.couponCount | commaSeparate }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <template v-if="generateView">
                        <v-expand-transition>
                          <v-text-field
                            v-model="noOfCoupons"
                            dense
                            outlined
                            class="px-4"
                            label="No of coupons to generate"
                            type="number"
                            :append-outer-icon="'mdi-close'"
                            :disabled="isGenerating"
                            @click:append-outer="generateView = false"
                          ></v-text-field>
                        </v-expand-transition>
                        <v-card-actions class="pa-0">
                          <v-btn
                            block
                            small
                            tile
                            :disabled="!noOfCoupons || noOfCoupons <= 0"
                            :loading="isGenerating"
                            @click="generateCoupons()"
                          >
                            Generate
                          </v-btn>
                        </v-card-actions>
                      </template>
                      <v-card-actions v-if="!generateView" class="pa-0">
                        <v-btn
                          v-if="model.summary && model.summary.couponCount == 0"
                          block
                          small
                          tile
                          @click="generateView = true"
                        >
                          Generate Coupons
                        </v-btn>
                        <v-btn v-else block small tile @click="exportCoupons()">
                          Export
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card flat outlined>
                      <v-list-item>
                        <v-list-item-icon class="my-5">
                          <v-icon>mdi-email-mark-as-unread</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-subtitle class="text-left">
                            Responses
                          </v-list-item-subtitle>
                          <v-list-item-title
                            v-if="model.summary"
                            class="headline text-right"
                          >
                            {{ model.summary.responseCount | commaSeparate }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-card-actions class="pa-0">
                        <v-btn block small tile @click="exportResponses()">
                          Export
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col style="flex-grow: 0">
                <v-card flat outlined>
                  <v-img
                    v-if="qr"
                    max-height="134"
                    max-width="134"
                    :src="qr"
                  ></v-img>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    min-width="134"
                    max-height="134"
                    type="card"
                  ></v-skeleton-loader>
                  <v-card-actions class="pa-0">
                    <v-btn block small @click="download()"> Download </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>
        <v-tabs grow>
          <v-tab>Responses</v-tab>
          <v-tab v-if="model.couponImage && model.couponProperties">
            Coupon PDF
          </v-tab>
          <v-tab v-if="model.summary && model.summary.couponCount !== 0">
            Coupons
          </v-tab>

          <v-tab-item>
            <response-list :promotionId="this.$route.params.id" />
          </v-tab-item>

          <v-tab-item v-if="model.couponImage && model.couponProperties">
            <coupon-pdf-list :couponCount="this.model.summary.couponCount" />
          </v-tab-item>

          <v-tab-item v-if="model.summary && model.summary.couponCount !== 0">
            <coupon-code-list :promotionId="this.$route.params.id" />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <v-dialog
      v-model="positionDialog"
      persistent
      width="650px"
      @keydown.esc="positionDialog = false"
    >
      <position-dialog
        :key="positionDialogKey"
        :promotion="model"
        @close="
          positionDialogKey++;
          positionDialog = false;
        "
        @updated="fetch()"
      ></position-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment';
import {
  downloadBase64,
  downloadBase64URL,
  downloadBlob,
} from '../../../helpers/file';
import BaseView from '../../../helpers/base-view';
import CouponCodeList from './CouponCodeList';
import CouponPdfList from './CouponPdfList';
import PositionDialog from './PositionDialog';
import PromotionEdit from './PromotionEdit';
import ResponseList from './ResponseList';

export default {
  name: 'PromotionView',

  extends: BaseView,

  components: {
    CouponPdfList,
    CouponCodeList,
    PositionDialog,
    PromotionEdit,
    ResponseList,
  },

  computed: {
    remainingDays() {
      let days = moment(this.model.endDate).diff(
        moment(this.model.startDate) > moment()
          ? moment(this.model.startDate)
          : moment(),
        'days'
      );
      return days > 0 ? days : 0;
    },

    appUrl() {
      return `${process.env.APP_URL}/promo/${this.model.code}`;
    },
  },

  data() {
    return {
      qr: null,
      positionDialog: false,
      positionDialogKey: 0,
      generateView: false,
      isGenerating: false,
      noOfCoupons: null,
    };
  },

  async created() {
    this.url = 'promotions';
    this.fetch();

    await this.$axios
      .get(`${this.url}/${this.$route.params.id}/generate-qr`)
      .then((response) => {
        this.qr = response.data;
      });
  },

  methods: {
    async generateCoupons() {
      this.isGenerating = true;

      await this.$axios
        .post(`${this.url}/${this.$route.params.id}/generate-coupons`, {
          quantity: this.noOfCoupons,
        })
        .then(() => {
          this.$root.$toast.open('Coupons Generated');
          this.generateView = false;
          this.noOfCoupons = null;
          this.fetch();
        })
        .catch(() => {
          this.$root.$toast.open('There was an error!', 'error');
        })
        .finally(() => {
          this.isGenerating = false;
        });
    },

    async exportCoupons() {
      if (this.model.summary.couponCount <= 0) return;

      let res = await this.$axios.get(
        `${this.url}/${this.$route.params.id}/export-coupons`,
        { responseType: 'blob' }
      );
      downloadBlob(res.data, `${this.model.code}-coupons`);
    },

    async exportResponses() {
      if (this.model.summary.responseCount <= 0) return;

      let res = await this.$axios.get(
        `${this.url}/${this.$route.params.id}/export-responses`,
        { responseType: 'blob' }
      );
      downloadBlob(res.data, `${this.model.code}-responses`);
    },

    download() {
      downloadBase64URL(this.qr, 'image/png', 'qr.png');
    },

    terminatePromotion() {
      this.$root.$confirm
        .open(
          `Are you sure?`,
          `You are about to terminate ${this.model.name}. This will set promotion end date to today and will no longer accept responses. Do you want to continue?`,
          'Continue',
          'Cancel',
          { width: '500px' }
        )
        .then(() => {
          this.$axios
            .put(`${this.url}/${this.$route.params.id}/end`)
            .then(() => {
              this.$root.$toast.open('Promotion Terminated');
              this.fetch();
            })
            .catch(() => {
              this.$root.$toast.open('There was an error!', 'error');
            });
        })
        .catch(() => {});
    },

    async downloadCouponsPdf() {
      try {
        let res = await this.$axios.get(
          `${this.url}/${this.$route.params.id}/coupons-pdf`
        );
        downloadBase64(res.data, 'application/pdf', 'coupons.pdf');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
