<template>
  <v-container fluid>
    <v-row>
      <v-col class="pa-0">
        <v-row dense class="ma-2">
          <v-col class="px-2">
            <v-text-field
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              @input="query"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="px-2">
            <v-select
              dense
              outlined
              v-model="prizeFilter"
              :items="[
                { name: 'Show coupons with prize', value: 'ne null' },
                { name: 'Show coupons without prize', value: 'eq null' },
                { name: 'Show all', value: null },
              ]"
              item-value="value"
              item-text="name"
              label="Filter"
              @input="query"
            ></v-select>
          </v-col>
          <v-col cols="3" class="px-2">
            <v-select
              dense
              outlined
              v-model="selectedValidity"
              :items="[
                { name: 'Invalid', value: false },
                { name: 'Valid', value: true },
              ]"
              item-value="value"
              item-text="name"
              label="Validity"
              @input="query"
            ></v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-data-table
          hide-default-header
          :footer-props="{ 'items-per-page-options': [10, 30, 60, 100] }"
          :items="data"
          :key="couponCodeListKey"
          :loading="loading"
          :options="pagination"
          :server-items-length="totalItems"
          @update:options="paginate"
        >
          <template v-slot:body="{ items }">
            <v-card
              flat
              tile
              class="d-flex flex-wrap justify-space-around pa-3"
            >
              <v-card
                v-for="item in items"
                :key="item.id"
                class="my-2"
                width="350"
                outlined
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="overline">
                      {{ item.isValid ? 'valid' : 'invalid' }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-h5">
                      {{ item.code }}
                    </v-list-item-title>
                    <v-file-input
                      v-model="item.image"
                      dense
                      hide-details
                      outlined
                      single-line
                      accept=".jpg,.jpeg,.png"
                      append-icon="mdi-paperclip"
                      label="Upload prize"
                      prepend-icon=""
                      @change="uploadImage(item)"
                    ></v-file-input>
                  </v-list-item-content>
                  <v-list-item-avatar tile size="105" color="grey">
                    <v-img
                      v-if="item.prize"
                      max-height="105"
                      max-width="105"
                      :src="item.prize"
                      class="my-4"
                    ></v-img
                  ></v-list-item-avatar>
                </v-list-item>
              </v-card>
            </v-card>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseList from '../../../helpers/base-list';

export default {
  extends: BaseList,

  components: {},

  props: {
    promotionId: {
      required: false,
    },
  },

  data() {
    return {
      couponCodeListKey: 1,
      prizeFilter: null,
      selectedValidity: true,
      sortField: 'id,desc',
    };
  },

  created() {
    this.url = 'coupons';
  },

  methods: {
    searchQuery(term) {
      return `code like ${term}`;
    },

    customParams(params) {
      params.filter =
        (typeof params.filter == 'string' ? `${params.filter}|` : '') +
        `promotionId eq ${this.promotionId}`;

      if (this.prizeFilter) {
        params.filter =
          (typeof params.filter == 'string' ? `${params.filter}|` : '') +
          `prize ${this.prizeFilter}`;
      }

      params.filter =
        (typeof params.filter == 'string' ? `${params.filter}|` : '') +
        `isValid eq ${this.selectedValidity}`;

      return params;
    },

    async uploadImage(item) {
      console.log(item);
      if (!item.image) return;

      item.loading = true;
      let formData = new FormData();
      formData.append('image', item.image, item.image.name);

      try {
        await this.$axios.post(`coupons/${item.id}/coupon-prize`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        this.couponCodeListKey++;
      } catch (error) {
        console.error(error);
        this.$root.$toast.open('There was an error!', 'error');
      } finally {
        item.loading = false;
      }
    },
  },
};
</script>
