export default {
  drag: false,
  targ: null,
  offsetX: 0,
  offsetY: 0,
  coordX: 0,
  coordY: 0,
  maxLeft: null,
  maxTop: null,
  minTop: null,

  startDrag(e, maxLeft, maxTop, onStop, minTop = 0) {
    // determine event object
    if (!e) {
      e = window.event;
    }
    if (e.preventDefault) e.preventDefault();

    // IE uses srcElement, others use target
    this.targ = e.target ? e.target : e.srcElement;

    if (!this.targ.className.includes('draggable')) {
      return;
    }

    this.maxLeft = maxLeft;
    this.maxTop = maxTop;
    this.minTop = minTop;

    // calculate event X, Y coordinates
    this.offsetX = e.clientX;
    this.offsetY = e.clientY;

    // assign default values for top and left properties
    if (!this.targ.style.left) {
      this.targ.style.left = '0px';
    }
    if (!this.targ.style.top) {
      this.targ.style.top = '0px';
    }

    // calculate integer values for top and left
    // properties
    this.coordX = parseInt(this.targ.style.left);
    this.coordY = parseInt(this.targ.style.top);
    this.drag = true;

    document.onmouseup = (e) => {
      this.stopDrag(e);
      if (onStop) {
        onStop({
          top: this.targ.style.top,
          left: this.targ.style.left,
        });
      }
    };

    // move element
    document.onmousemove = (e) => {
      return this.dragDiv(e);
    };
    return false;
  },

  dragDiv(e) {
    if (!this.drag) {
      return;
    }
    if (!e) {
      e = window.event;
    }
    // var targ=e.target?e.target:e.srcElement;
    const left = this.coordX + e.clientX - this.offsetX;
    const top = this.coordY + e.clientY - this.offsetY;

    this.targ.style.left = left < 0 ? '0px' : `${left}px`;
    this.targ.style.top = top < this.minTop ? `${this.minTop}px` : `${top}px`;

    if (left > this.maxLeft) this.targ.style.left = `${this.maxLeft}px`;
    if (top > this.maxTop) this.targ.style.top = `${this.maxTop}px`;

    return false;
  },

  stopDrag() {
    this.drag = false;
    document.onmousemove = null;
  },
};
